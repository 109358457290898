<template>
  <crud-form
    :titulo-pagina="tituloPagina"
    :is-editting="isEditting"
    :mensagem-pergunta-remocao="mensagemPerguntaRemocao"
    :mostra-botao-salvar-continuar="false"
    @deleteEvent="remover"
    @closeDialogEvent="closeDialog"
    @saveEvent="saveEventHandler"
  >
    <v-container class="py-0">
      <v-row>
        <v-col
          v-if="!idEmpresaCliente || idEmpresaCliente < 0"
          cols="12"
          sm="6"
        >
          <empresa-cliente-auto-complete
            v-model="empresaClienteModel"
            :readonly="!!empresaClienteModelId || isEditting"
            :clearable="!isEditting"
          />
        </v-col>
        <v-col :class="[{'xs12' : idEmpresaCliente}, {'xs12 sm6' : !idEmpresaCliente || idEmpresaCliente < 0}]">
          <v-text-field
            v-model="objEditar.nomeTipoEvento"
            class="purple-input"
            :label="$t('TipoEventosForm.nomeTipoEvento')"
          />
        </v-col>
      </v-row>
    </v-container>
  </crud-form>
</template>

<script>

  import TipoEventoService from '@/services/TipoEventoService'
  import { mapState } from 'vuex'
  import EmpresaClienteAutoComplete from '@/views/EmpresaClienteAutoComplete'

  export default {
    components: {
      EmpresaClienteAutoComplete
    },
    data: () => ({
      objPadrao: {
        nomeTipoEvento: '',
        idEmpresaCliente: null
      },
      objEditar: {
        nomeTipoEvento: '',
        idEmpresaCliente: null
      },
      empresaClienteModel: null,
      isEditting: true
    }),

    computed: {
      ...mapState(['idEmpresaCliente']),
      mensagemPerguntaRemocao () {
        return this.$i18n.t('padrao_deseja_remover_registro')
      },
      tituloPagina () {
        return this.$i18n.t('TipoEventosForm.tituloPagina')
      }
    },
    watch: {
      empresaClienteModel (val) {

        if (!val || val === null) {
          this.objEditar.idEmpresaCliente = null
          this.objEditar.EmpresaCliente = null
        } else {
          this.objEditar.idEmpresaCliente = val.id
          this.objEditar.EmpresaCliente = val
        }
      }
    },
    created () {
      this.$root.$emit('alteraMostraPesquisa', false)

      let id = this.$route.params.id
      if (id) {
        this.editar(id)
      } else {
        this.isEditting = false
        this.objEditar = this.objPadrao
      }
    },
    methods: {
      saveContinueEvent () {
        if (this.idEmpresaCliente) {
          this.objEditar.idEmpresaCliente = this.idEmpresaCliente
        }
        this.inserir(this.objEditar, true)
      },
      saveEventHandler () {
        if (this.idEmpresaCliente && this.idEmpresaCliente >= 0) {
          this.objEditar.idEmpresaCliente = this.idEmpresaCliente
        }
        if (this.isEditting) {
          this.atualizar(this.objEditar)
        } else {
          this.inserir(this.objEditar)
        }
      },
      atualizar (registro) {
        try {
          TipoEventoService.update(registro)
            .then(response => {
              if (!response) {
                this.$root.$emit('mostra_dialogo_erro', {
                  dialogoDescricao: 'Deu algum erro,\nResposta do Servidor: null '
                })
              } else if (response.error) {
                this.$root.$emit('mostra_dialogo_erro', {
                  dialogoDescricao: '' + response.error
                })
              } else {
                this.$root.$emit('mostra_dialogo_info', {
                  dialogoTitulo: this.$t('padrao_sucesso'),
                  dialogoDescricao: this.$t('padrao_registro_salvo_sucesso')
                })
                this.closeDialog()
              }
            })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao: 'Não foi possível concluir a atualização, tente mais tarde\n' + error
          })
        }
      },
      closeDialog () {
        this.$router.push('/tipoEventos')
      },
      remover () {
        const tipoEvento = this.objEditar
        try {
          TipoEventoService.delete(tipoEvento)
            .then(response => {
              this.$root.$emit('mostra_dialogo_info', {
                dialogoTitulo: this.$t('padrao_sucesso'),
                dialogoDescricao: this.$t('padrao_registro_removido_sucesso')
              })
              this.closeDialog()
            })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao: 'Ocorreu um erro ao remover um tipo de Evento:\n ->' + error
          })
        }
      },
      inserir (tipoEvento, manterNestaTela) {
        try {
          TipoEventoService.insert(tipoEvento).then(response => {
            if (!response) {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: 'Deu algum erro,\nResposta do Servidor: null '
              })
            } else if (response.error) {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: '' + response.error
              })
            } else {
              this.$root.$emit('mostra_dialogo_info', {
                dialogoTitulo: this.$t('padrao_sucesso'),
                dialogoDescricao: this.$t('padrao_registro_salvo_sucesso')
              })
              if (manterNestaTela) {
                this.editar(tipoEvento.data.id)
              } else {
                this.closeDialog()
              }
            }
          }).catch(error => {
            this.$root.$emit('mostra_dialogo_erro', {
              dialogoDescricao: 'Ocorreu um erro ao inserir um Item:\n ->' + error
            })
          })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao: 'Ocorreu um erro ao inserir um tipo de evento:\n ->' + error
          })
        }
      },
      editar (id) {
        TipoEventoService.findById({ id })
          .then(tipoEvento => {
            if (tipoEvento.id) {
              this.objEditar = tipoEvento
              this.isEditting = true
            } else if (tipoEvento.data.id) {
              this.objEditar = tipoEvento.data
              this.empresaClienteModel = this.objEditar.EmpresaCliente
              this.isEditting = true
            } else {
              this.isEditting = false
            }
          })
          .catch(() => {
            this.isEditting = false
          })
      }
    }
  }
</script>
